import { graphql, Link, navigate, useStaticQuery } from 'gatsby';
import parse from 'html-react-parser';
import React, { useEffect, useState } from 'react';
import { FluxDnaSettings } from '../Footer/FluxDnaSettings';
import './Header.scss';
import MainSiteInnerNav from './MainSiteInnerNav';

const Header = ({ microSitename, location }) => {
  const fluxFooterSetting = FluxDnaSettings();
  const [menuOpenStatus, setMenuOpenStatus] = useState(false);
  const [searchMenu, setSearchMenu] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false);
  const [menuOpenBooking, setMenuOpenBooking] = useState(false);
  const [isImage, setisImage] = useState(null);
  const [searchtext, setSearchtext] = useState('');
  const [searchParams, setSearchParams] = useState(null);
  const [url, setURL] = useState('/guest-enquiry/');
  const [urltwo, setURLtwo] = useState(
    'https://bookings.theroyalportfolio.com/'
  );
  const [pagePath, setPagePath] = useState(0);

  useEffect(() => {
    if (location) {
      setPagePath(location.pathname);
    }
  });

  const query = useStaticQuery(graphql/* GraphQL */ `
    query {
      wp {
        fluxDnaSettings {
          pageTitle
          pageSlug
          fluxDna {
            royalPortfolio {
              cta {
                target
                title
                url
              }
              primaryLogo {
                altText
                imgixImage {
                  url(imgixParams: { w: 200, h: 149, dpr: 2, q: 100 })
                }
                uri
                title
              }
              secondaryLogo {
                altText
                imgixImage {
                  url(imgixParams: { w: 40, h: 43, dpr: 2, q: 100 })
                }
                uri
                title
              }
            }
          }
        }
      }
    }
  `);

  useEffect(() => {
    if (microSitename === 'Royal Malewane') {
      setURL('/royal-malewane/guest-enquiry');
      setURLtwo('https://bookings.theroyalportfolio.com/royalmalewane');
    } else if (microSitename === 'Birkenhead House') {
      setURL('/birkenhead-house/guest-enquiry');
      setURLtwo('https://bookings.theroyalportfolio.com/birkenheadhouse');
    } else if (microSitename === 'La Residence') {
      setURL('/la-residence/guest-enquiry');
      setURLtwo('https://bookings.theroyalportfolio.com/laresidence');
    } else if (microSitename === 'The Silo Hotel') {
      setURL('/the-silo-hotel/guest-enquiry');
      setURLtwo('https://bookings.theroyalportfolio.com/thesilo');
    }
    var url_string = window.location.href;
    var url = new URL(url_string);
    var param = url.searchParams.get('q');
    setSearchtext(param ? param : '');
    setSearchParams(param ? param : '');
    document.body.classList.remove('nav-menu-open');
    document.body.classList.remove('booking-menu-open');
  }, []);

  useEffect(() => {}, [searchParams]);

  const data_header = query.wp.fluxDnaSettings.fluxDna;
  const callback = (image) => {
    if (image) {
      setisImage(image);
    }
  };

  const toggleMenu = () => {
    document.body.classList.toggle('nav-menu-open');
    setMenuOpen(!menuOpen);
  };

  return (
    <>
      <header className="header lg:py-20 py-15">
        <div className="container-fluid">
          <nav className="navbar relative grid grid-cols-3 gap-3 smscreen2:flex  justify-between items-center">
            <div className="headerLeft flex items-center">
              <div className="menu-icon flex items-center ">
                <div
                  onClick={toggleMenu}
                  className={`navbar-icon flex flex-col gap-y-1 items-center cursor-pointer hamburger ${
                    menuOpen ? 'active' : ''
                  }`}
                >
                  <div className="flex flex-wrap w-30 space-y-2 menu-line">
                    <span className="line"></span>
                    <span className="line"></span>
                    <span className="line"></span>
                  </div>
                  <span className="uppercase text-10 menu-text">Menu</span>
                </div>
              </div>
              <div className="hidden mdscreen:block">
                <div className="mobile-search w-160 smscreen2:w-auto flex items-center">
                  <div
                    className={`mobile-search-content text-left z-9 w-[160px] smscreen2:top-0 smscreen2:z-[999999] smscreen2:left-0 smscreen2:w-full absolute ml-20 block  ${
                      searchMenu ? 'searchOpen' : ''
                    }`}
                  >
                    <div
                      className={`mobile-search-wrapper bg-white relative pt-20 ${
                        searchMenu ? '' : 'hidden'
                      }`}
                    >
                      <button
                        className={`modal-close z-9 absolute top-10 left-10 lgscreen:w-50 lgscreen:h-50 inline-block rounded-100 text-center`}
                        onClick={() => setSearchMenu(!searchMenu)}
                      >
                        <img
                          src="/images/close2.png"
                          className="m-auto lgscreen:max-w-20"
                          alt="close"
                        />
                      </button>
                      <Link to="/">
                        <img
                          src={
                            data_header.royalPortfolio.secondaryLogo.imgixImage
                              .url
                          }
                          onClick={() => {
                            document.body.classList.remove('nav-menu-open');
                            document.body.classList.remove('booking-menu-open');
                            setMenuOpen(false);
                          }}
                          alt="Logo"
                          className="h-[51px] object-contain mx-auto"
                          loading="lazy"
                        />
                      </Link>
                      <form
                        onSubmit={(event) => {
                          event.preventDefault();
                          setSearchMenu(false);
                          navigate(
                            encodeURI('/search-results?q=' + searchtext),
                            {
                              state: searchtext,
                            }
                          );
                        }}
                        className="pt-30 pb-40 px-60 flex justify-center relative"
                      >
                        <input
                          type="text"
                          value={searchtext}
                          onChange={(e) => {
                            setSearchtext(e.target.value);
                          }}
                          placeholder="Search"
                          className="searchFocus !w-[260px] py-15 placeholder:text-gray-100 placeholder:uppercase bg-transparent border-b-1 border-gray-100 text-12 mdscreen:text-16 outline-none"
                        />
                        <button className="ml-20 absolute top-[47px] right-[78px] z-999">
                          <img
                            src="https://wp.theroyalportfolio.com/app/uploads/2024/02/search-icon2.png"
                            className="max-w-20 ml-auto"
                            alt=""
                          />
                        </button>
                      </form>
                    </div>
                  </div>

                  <button
                    className="ml-20 relative z-99"
                    id="mobileSearchIcon"
                    onClick={() => {
                      setSearchMenu(!searchMenu);
                      if (!searchMenu) {
                        document
                          .querySelectorAll('.mobile-search .searchFocus')[0]
                          .focus();
                      }
                    }}
                  >
                    <img
                      src="https://wp.theroyalportfolio.com/app/uploads/2024/02/search-icon2.png"
                      className="max-w-20 ml-auto"
                      alt=""
                    />
                  </button>
                </div>
              </div>
            </div>
            <div className="logo mx-auto">
              <Link to="/">
                <img
                  src={data_header.royalPortfolio.primaryLogo.imgixImage.url}
                  onClick={() => {
                    document.body.classList.remove('nav-menu-open');
                    document.body.classList.remove('booking-menu-open');
                    setMenuOpen(false);
                  }}
                  width="160"
                  height="80"
                  loading="eager"
                  className="m-auto desktop-logo w-[110px] h-[55px] md:w-[160px] md:h-[80px]"
                  alt="The Royal Portfolio Logo"
                />
              </Link>
              <Link to="/">
                <img
                  src={data_header.royalPortfolio.secondaryLogo.imgixImage.url}
                  onClick={() => {
                    document.body.classList.remove('nav-menu-open');
                    document.body.classList.remove('booking-menu-open');
                    setMenuOpen(false);
                  }}
                  alt="Logo"
                  className="m-auto lg:w-[149px] sticky-logo"
                  loading="eager"
                />
              </Link>
            </div>
            <div className="header-right relative flex items-center justify-end">
              <div className="mobile-search w-160 smscreen2:w-auto justify-end lg:flex items-center lgscreen:flex mdscreen:hidden">
                <div
                  className={`mobile-search-content text-right z-9 w-[160px] smscreen2:top-[70px] smscreen2:left-0 smscreen2:w-full absolute mr-20  ${
                    searchMenu ? 'searchOpen' : ''
                  }`}
                >
                  <form
                    onSubmit={(event) => {
                      event.preventDefault();
                      navigate(encodeURI('/search-results?q=' + searchtext), {
                        state: searchtext,
                      });
                    }}
                  >
                    <input
                      type="text"
                      value={searchtext}
                      onChange={(e) => {
                        setSearchtext(e.target.value);
                      }}
                      className="searchFocus w-full py-15 placeholder:text-black-300 bg-transparent border-b-1 border-gray-100 text-14 outline-none"
                    />
                  </form>
                </div>

                <button
                  className="mx-20 smscreen2:ml-0 relative z-99"
                  id="mobileSearchIcon"
                  onClick={() => {
                    setSearchMenu(!searchMenu);
                    if (!searchMenu) {
                      document
                        .querySelectorAll('.mobile-search .searchFocus')[0]
                        .focus();
                    }
                  }}
                >
                  <img
                    src="/images/search-icon2.png"
                    className="max-w-20 ml-auto"
                    alt=""
                  />
                </button>
              </div>
              <div className="booking-btn ml-20 xlscreen:ml-5">
                <div
                  onClick={() => {
                    document.body.classList.toggle('booking-menu-open');
                    setMenuOpenBooking(!menuOpenBooking);
                  }}
                  className={`button button-gray cursor-pointer lg:block hidden ${
                    menuOpenBooking ? 'active' : ''
                  }`}
                >
                  Make A Booking
                </div>

                <div
                  onClick={() => {
                    document.body.classList.toggle('booking-menu-open');
                    setMenuOpenBooking(!menuOpenBooking);
                  }}
                  className={`button button-gray cursor-pointer lg:hidden block ${
                    menuOpenBooking ? 'active' : ''
                  }`}
                >
                  Book
                </div>
              </div>
            </div>
          </nav>
        </div>
      </header>
      <div className="main-nav nav-container">
        <div className="menu-overlay h-full fixed w-full top-0 left-0 bg-gray2"></div>
        <div className="mainNavimg mdscreen:hidden">
          {isImage ? (
            <img
              src={isImage}
              loading="lazy"
              className="ml-auto mainNavbgImg"
              alt=""
            />
          ) : null}
        </div>
        <div className="mainNav__wrap relative m-auto p-menu_mobile z-99 h-full">
          <div className="container-fluid h-full">
            <div className="mainNav__wrap--inner h-full flex flex-col justify-center  mobile-menu">
              <div className="navbarMoile lg:pl-80 pt-20">
                <div className="max-w-[600px] lgscreen:max-w-[350px] smscreen:max-w-[280px] flex flex-col">
                  <div className="mainNav__col navMenu relative mainNav__col--right flex flex-col pt-20">
                    <div className="btn-custom bk-btn relative z-999 hidden">
                      <div
                        onClick={() => {
                          document.body.classList.toggle('booking-menu-open');
                          setMenuOpenBooking(!menuOpenBooking);
                        }}
                        className={`button button-transparent cursor-pointer hamburger menu-open-test transition-all duration-300 ${
                          menuOpenBooking ? 'active' : ''
                        }`}
                      >
                        Make A Booking
                      </div>
                    </div>
                    <MainSiteInnerNav callback={callback} />
                  </div>
                </div>
                <div className="w-7/12 mdscreen:w-full">
                  <div className="flex justify-between flex-col items-start">
                    <div className="header-social-icon">
                      <div className="footer-info pt-20">
                        {parse(fluxFooterSetting.footer.contactInformation)}
                        <Link
                          to={data_header.royalPortfolio.cta.url}
                          className="link-btn"
                        >
                          {data_header.royalPortfolio.cta.title}
                        </Link>
                        <div className="sicon">
                          <ul className="flex flex-wrap items-center mt-20 lg:space-x-5 lgscreen:space-x-3 ">
                            <li>
                              <a
                                target="_blank"
                                href="https://www.instagram.com/theroyalportfolio/?hl=en"
                              >
                                <svg
                                  width="20"
                                  height="20"
                                  viewBox="0 0 20 20"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M10.0007 6.74956C8.20575 6.74956 6.75067 8.20463 6.75067 9.99956C6.75067 11.7945 8.20575 13.2496 10.0007 13.2496C11.7956 13.2496 13.2507 11.7945 13.2507 9.99956C13.2507 8.20463 11.7956 6.74956 10.0007 6.74956Z"
                                    fill="#C4C4C4"
                                  />
                                  <path
                                    fillRule="evenodd"
                                    clipRule="evenodd"
                                    d="M4.77021 1.08103C8.21833 0.695648 11.783 0.695648 15.2312 1.08103C17.1296 1.2932 18.6607 2.78898 18.8836 4.69403C19.2958 8.21903 19.2958 11.7801 18.8836 15.3051C18.6607 17.2101 17.1296 18.7059 15.2312 18.9181C11.783 19.3035 8.21833 19.3035 4.77021 18.9181C2.87181 18.7059 1.34062 17.2101 1.1178 15.3051C0.705523 11.7801 0.705523 8.21903 1.1178 4.69403C1.34062 2.78898 2.87181 1.2932 4.77021 1.08103ZM15.0007 3.99956C14.4484 3.99956 14.0007 4.44727 14.0007 4.99956C14.0007 5.55184 14.4484 5.99956 15.0007 5.99956C15.553 5.99956 16.0007 5.55184 16.0007 4.99956C16.0007 4.44727 15.553 3.99956 15.0007 3.99956ZM5.25067 9.99956C5.25067 7.3762 7.37732 5.24956 10.0007 5.24956C12.624 5.24956 14.7507 7.3762 14.7507 9.99956C14.7507 12.6229 12.624 14.7496 10.0007 14.7496C7.37732 14.7496 5.25067 12.6229 5.25067 9.99956Z"
                                    fill="#C4C4C4"
                                  />
                                </svg>
                              </a>
                            </li>
                            <li>
                              <a
                                target="_blank"
                                href="https://www.facebook.com/RoyalPortfolio/"
                              >
                                <svg
                                  width="12"
                                  height="20"
                                  viewBox="0 0 12 20"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M8.2 0.875C6.97337 0.875 5.79699 1.36228 4.92963 2.22963C4.06228 3.09699 3.575 4.27337 3.575 5.5V8.075H1.1C0.975736 8.075 0.875 8.17574 0.875 8.3V11.7C0.875 11.8243 0.975736 11.925 1.1 11.925H3.575V18.9C3.575 19.0243 3.67574 19.125 3.8 19.125H7.2C7.32426 19.125 7.425 19.0243 7.425 18.9V11.925H9.92192C10.0252 11.925 10.1152 11.8547 10.1402 11.7546L10.9902 8.35457C11.0257 8.21256 10.9183 8.075 10.7719 8.075H7.425V5.5C7.425 5.29446 7.50665 5.09733 7.65199 4.95199C7.79733 4.80665 7.99446 4.725 8.2 4.725H10.8C10.9243 4.725 11.025 4.62426 11.025 4.5V1.1C11.025 0.975736 10.9243 0.875 10.8 0.875H8.2Z"
                                    fill="#C4C4C4"
                                  />
                                </svg>
                              </a>
                            </li>
                            <li>
                              <a
                                target="_blank"
                                href="https://www.youtube.com/channel/UCv2IKRPT4gL5LzzR8PIpAVg"
                              >
                                <svg
                                  width="22"
                                  height="16"
                                  viewBox="0 0 22 16"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    fillRule="evenodd"
                                    clipRule="evenodd"
                                    d="M5.98998 0.890061C9.32565 0.629094 12.6766 0.629094 16.0123 0.890061L18.253 1.06536C19.501 1.16301 20.5221 2.0984 20.7284 3.33317C21.2446 6.42267 21.2446 9.57641 20.7284 12.6659C20.5221 13.9007 19.501 14.8361 18.253 14.9337L16.0123 15.109C12.6766 15.37 9.32565 15.37 5.98998 15.109L3.74928 14.9337C2.50121 14.8361 1.48018 13.9007 1.27387 12.6659C0.757667 9.57641 0.757667 6.42267 1.27387 3.33317C1.48018 2.0984 2.50121 1.16301 3.74928 1.06536L5.98998 0.890061ZM9.0011 10.4697V5.5294C9.0011 5.29621 9.25549 5.15218 9.45545 5.27215L13.5724 7.74229C13.7666 7.85882 13.7666 8.14027 13.5724 8.25679L9.45545 10.7269C9.25549 10.8469 9.0011 10.7029 9.0011 10.4697Z"
                                    fill="#C4C4C4"
                                  />
                                </svg>
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="booking-container ">
        <div
          onClick={() => {
            document.body.classList.toggle('booking-menu-open');
            setMenuOpenBooking(!menuOpenBooking);
          }}
          className="button button-darkgray absolute right-[80px] xlscreen:right-40 top-[50px] back-button cursor-pointer transition-all duration-300"
        >
          <div className="flex flex-wrap w-30 space-y-2">
            <span className="line"></span>
            <span className="line"></span>
          </div>
        </div>
        <div className="flex justify-end h-full w-30-per desktop2:w-40-per lgscreen:w-50-per mdscreen:w-70-per smscreen2:w-full ml-auto bg-white">
          <div className="lg:w-12/12 m-auto mr-0 w-full">
            <div className="xl:px-100 lg:px-50 px-50">
              <div
                className="
               text-right border-b-1 border-gray-900 pb-50 mb-30"
              >
                <div className="title-black">
                  <h3 className="text-black-100 text-37 font-400">
                    Direct Booking
                  </h3>
                </div>

                <div className="content mt-10 mdscreen:mt-20">
                  <p>Book directly with us.</p>
                </div>
                <div className="btn-custom justify-end mt-30">
                  <a href={urltwo} className="button button-gray">
                    Make a Booking
                  </a>
                </div>
              </div>
              <div className="booking-info-container text-right">
                <div className="title-black">
                  <h3 className="text-black-100 text-37 font-400">
                    Guest Enquiry
                  </h3>
                </div>
                <div className="content">
                  <p>
                    Not ready to book just yet? Submit an enquiry and our team
                    will be in touch within 24 hours.
                  </p>
                </div>
                <div className="btn-custom justify-end mt-30">
                  <Link
                    to={url}
                    state={{ prevPath: pagePath }}
                    className="button button-gray"
                  >
                    Make an Enquiry
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Header;
