import { graphql, navigate, useStaticQuery } from "gatsby";
import React, { useRef, useState } from "react";
import "../components/Header/Header.scss";
import Header from '../components/Header/Header';

const NewsletterSignup = (props) => {

  const [formSubmitted, setformSubmitted] = useState(false);

  const [form, setForm] = useState({
    input_1: "",
    input_3: "",
    input_4: "",
    input_7: "",
  });

  const [formError, setFormError] = useState({
    input_1: "",
    input_3: "",
    input_4: "",
    input_7: "",
  });

  return (
    <>
      <Header />

      <section className="section-title lg:p-40 py-20 smscreen2:pb-0">
        <div className="fade-ani px-30 text-center pt-[150px]">
          <div className="title-black text-center">
            <h1>TRP Staff Newsletter Signup</h1>
          </div>
        </div>
      </section>

      <div className="traveller-enquiry-form bg-white rate-form mdscreen:m-20">
            <div className="flex flex-wrap">
              <div className="lg:w-12/12 w-full ">
                <div className="xl:px-80 lg:px-40 px-20 lg:py-40 pb-20 pt-20 relative">
                  <div className="container-fluid-md">
                    <div className="gform_wrapper">
                    {!formSubmitted && (
                    <form autoComplete="on">
                        <div className="gform_body">
                          <div className="gap-2 grid mdscreen:grid-cols-1 grid-cols-3">
                            <div className="form-group flex flex-wrap lg:mt-30 mt-10">
                              <label
                              htmlFor="first-name">
                                First Name<span>*</span>
                              </label>
                              <input
                                type="text"
                                name="first-name"
                                id="first-name"
                                autoComplete="given-name"
                                value={form.input_1}
                                onChange={(e) => {
                                  setForm({
                                    ...form,
                                    input_1: e.target.value,
                                  });
                                  setFormError({
                                    ...formError,
                                    input_1: "",
                                  });
                                }}
                              />
                              {formError.input_1 && (
                                <div className="gravityform__error_message gfield_description validation_message">
                                  {formError.input_1}
                                </div>
                              )}
                            </div>
                            <div className="form-group flex flex-wrap lg:mt-30 mt-10">
                              <label
                              htmlFor="last-name">
                                Surname<span>*</span>
                              </label>
                              <input
                                value={form.input_3}
                                type="text"
                                name="last-name"
                                id="last-name"
                                autoComplete="family-name"
                                onChange={(e) => {
                                  setForm({
                                    ...form,
                                    input_3: e.target.value,
                                  });
                                  setFormError({
                                    ...formError,
                                    input_3: "",
                                  });
                                }}
                              />
                              {formError.input_3 && (
                                <div className="gravityform__error_message gfield_description validation_message">
                                  {formError.input_3}
                                </div>
                              )}
                            </div>
                            <div className="form-group flex flex-wrap lg:mt-30 mt-10">
                              <label
                              htmlFor="email">
                              Email Address<span>*</span>
                              </label>
                              <input
                                value={form.input_4}
                                type="email"
                                name="email"
                                id="email"
                                autoComplete="email"
                                onChange={(e) => {
                                  setForm({
                                    ...form,
                                    input_4: e.target.value,
                                  });
                                  setFormError({
                                    ...formError,
                                    input_4: "",
                                  });
                                }}
                              />
                              {formError.input_4 && (
                                <div className="gravityform__error_message gfield_description validation_message">
                                  {formError.input_4}
                                </div>
                              )}
                            </div>
                          </div>
                          <div className="form-group text-area flex flex-wrap pt-40">
                              <div className="radio-bx checkbox-bx">
                                <input
                                  type="checkbox"
                                  value="Yes"
                                  className="hidden"
                                  id="input_7"
                                  required
                                  onChange={(e) => {
                                    setForm({
                                      ...form,
                                      input_7: "Yes",
                                    });
                                    setFormError({
                                      ...formError,
                                      input_7: "",
                                    });
                                  }}
                                />
                                <label
                                  htmlFor="input_7"
                                  className="text-dark text-16 !pb-5"
                                >
                                  I agree to receive news and updates and consent to my submitted data being collected and stored
                                </label>
                              </div>
                            </div>
                            {formError.input_7 && (
                              <div className="error">
                                <span className="text-red">{formError.input_7}</span>
                              </div>
                            )}
                          <div className="text-center inline-block mt-20 w-full">
                            <button className="button button-gray cursor-pointer" type="button" onClick={() => {
                              var validRegex =
                                /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

                              setFormError({
                                ...formError,
                                input_1:
                                  form.input_1 === ""
                                    ? "This field is required."
                                    : "",
                                input_3:
                                  form.input_3 === ""
                                    ? "This field is required."
                                    : "",
                                input_4:
                                  form.input_4 === ""
                                    ? "This field is required."
                                    : "",
                                input_7:
                                  form.input_7 === ""
                                    ? "This field is required."
                                    : "",
                              });
                              if (
                                form.input_1 !== "" &&
                                form.input_3 !== "" &&
                                form.input_4 !== "" &&
                                form.input_7 !== ""
                              ) {

                                const requestOptions = {
                                  method: "POST",
                                  headers: {
                                    "Content-Type": "application/json",
                                  },
                                  body: JSON.stringify(form),
                                };
                                const url = `${process.env.GATSBY_WP_URL}wp-json/gf/v2/forms/29/submissions`;
                                fetch(url, requestOptions).then((response) => {
                                  if (response.status === 200) {
                                    setformSubmitted(true);
                                  } else {
                                    setFormError({
                                      ...formError,
                                      input_1: "Please try again.",
                                    });
                                  }
                                });
                              }
                            }}>Submit</button>
                          </div>
                        </div>
                    </form>
                    )}
                    {formSubmitted && (
                        <div className="thankyou-msg">
                        Thank you for signing up to our newsletters – we look forward to sharing our stories with you.
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
      </div>
    </>
  );
};

export default NewsletterSignup;
export const Head = () => (
  <title>Staff Newsletter Signup | The Royal Portfolio</title>
);

